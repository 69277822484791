import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'

import PlayIcon from '../../../static/images/play-button.svg'
import './Video.scss'

const Video = ({
  embedURL, hostedURL, title, containerSize, image, id,
}) => {
  const [play, togglePlay] = React.useState(false)
  const playVideo = () => {
    if (hostedURL) {
      const videoElement = document.getElementById(id)
      if (videoElement) {
        videoElement.play()
      }
    }
    togglePlay(!play)
  }

  const renderCoverImageAndPlayVideo = () => (
    !play && (
      <div className='video-overlay'>
        <div className='video-overlay-color' />
        <GatsbyImage fluid={image.fluid} alt={image.alt} />
        <button type='button' onClick={playVideo}>
          <PlayIcon />
          Play Video
        </button>
      </div>
    )
  )

  return (
    <div className='video-container' style={{ paddingBottom: containerSize === 'full' ? '56.25%' : '63.8%' }}>
      {
        hostedURL && (
          <div>
            {renderCoverImageAndPlayVideo()}
            <video loop controls className='video' autoplay={play} id={id}>
              <source src={hostedURL} type='video/mp4' />
            </video>
          </div>
        )
      }
      {
        embedURL && (
          <div>
            {renderCoverImageAndPlayVideo()}
            <iframe
              className='video'
              src={`${embedURL}${play ? '?autoplay=1' : ''}`}
              title={title}
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              frameBorder='0'
              webkitallowfullscreen='true'
              mozallowfullscreen='true'
              allowFullScreen
            />
          </div>
        )
      }
    </div>
  )
}

Video.defaultProps = {
  id: 'video',
}

Video.propTypes = {
  id: PropTypes.string,
  embedURL: PropTypes.string,
  hostedURL: PropTypes.string,
  title: PropTypes.string,
  containerSize: PropTypes.string.isRequired,
  image: PropTypes.object,
}

export default Video
