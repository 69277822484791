import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import Video from './Video'
import './Images.scss'

const Media = ({ id, data }) => {
  const {
    image, video_embed_url: videoEmbedUrl, hosted_video: hostedVideo, name, container_size:  size,
  } = data
  let component
  if (videoEmbedUrl) {
    component = <Video image={image} containerSize={size} embedURL={videoEmbedUrl} title={name} />
  } else if (hostedVideo && hostedVideo.url) {
    component = <Video image={image} id={id} containerSize={size} hostedURL={hostedVideo.url} title={name} />
  } else if (image && image.fluid) {
    component = <GatsbyImage className='image' fluid={image.fluid} alt={image.alt} />
  } else if (image && image.url) {
    component = <img className='image' src={image.url} alt={image.alt} />
  }
  return <div style={{ flex: size === 'full' ? '100%' : '50%' }}>{component}</div>
}

Media.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
}

export const query = graphql`
  fragment PrismicMediaFragment on PrismicLinkType {
    document {
      ...on PrismicMedia {
        id
        data {
          name
          image {
            alt
            url
            fluid {
              sizes
              src
              aspectRatio
              base64
              srcWebp
              srcSetWebp
            }
          }
          container_size
          video_embed_url
          hosted_video {
            url
          }
        }
      }
    }
  }
`

export default Media
