import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import RequestContactForm from '../contact/requestContactForm'
import { RichText } from '../html'
import Title from './Title'

import './BannerWithForm.scss'

const BannerWithForm = ({ body }) => {
  const { primary } = body
  const {
    form_id: formId,
    background_image: backgroundImage,
    bannerTitle,
    bannerDescription,
  } = primary

  const style = {
    backgroundImage: `url(${backgroundImage.url})`,
    backgroundSize: 'cover',
  }

  if (formId === 'request-contact-back') {
    return (
      <div className='request-contact-back' style={style}>
        <div className='text-column'>
          <Title title={bannerTitle} />
          <RichText render={bannerDescription} />
        </div>
        <div className='form-column'>
          <RequestContactForm productOptions={[]} />
        </div>
      </div>
    )
  }
  return (
    <div className=''>
      Form id not implemented {formId}
    </div>
  )
}

export const query = graphql`
  fragment BannerWithFormFragment on PrismicPageBodyBannerWithForm {
    slice_type
    primary {
      bannerTitle: title {
        html
      }
      bannerDescription: description {
        html
      }
      form_id
      background_image {
        url
      }
    }
  }
`

BannerWithForm.propTypes = {
  body: PropTypes.object,
}

export default BannerWithForm
