import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'

import './AwardsBanner.scss'

const AwardsBanner = ({ body }) => {
  const { primary } = body
  const { data } = primary.awards_content.document
  const style = {
    backgroundImage: `url(${data.background.url})`,
    backgroundSize: 'cover',
  }
  return (
    <div style={style} className='banner-wrapper full-padding'>
      <div className='banner-logos-wrapper'>
        {data.awards && data.awards.map((item) => (
          <div>
            <div><img src={item.image.url} alt={item.image.alt} /></div>
            <div><span>{item.image.alt}</span></div>
          </div>
        ))}
      </div>
      <div className='banner-text-content'>
        <RichText render={data.title.raw} />
        <RichText render={data.description.raw} />
      </div>
    </div>
  )
}

AwardsBanner.propTypes = {
  body: PropTypes.object,
}

export const query = graphql`
  fragment AwardsBannerFragment on PrismicPageBodyAwardsBanner {
    slice_type
    primary {
      awards_content {
        document {
          ...on PrismicAwards {
            data {
              description {
                raw
              }
              title {
                raw
              }
              background {
                url
              }
              awards {
                image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AwardsBanner
