import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import ButtonBar from '../button/ButtonBar'
import './ActionBanner.scss'

const ActionBanner = ({ body }) => {
  const { primary } = body

  let buttonArray
  if (primary.buttons) {
    const { button: _buttonArray } = primary.buttons.document.data
    buttonArray = _buttonArray
  }

  return (
    <div className='action-banner'>
      <RichText render={primary.actionBannerTitle.raw} />
      <ButtonBar buttonArray={buttonArray} />
    </div>
  )
}

export const query = graphql`
  fragment ActionBannerFragment on PrismicPageBodyActionBanner {
    slice_type
    primary {
      actionBannerTitle: title {
        raw
      }
      buttons {
        document {
          ... PrismicButtonFragment
        }
      }
    }
  }
`

ActionBanner.propTypes = {
  body: PropTypes.object,
}

export default ActionBanner
