import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import {
  AwardsBanner,
  ServiceSummary,
  Hero,
  ProjectsSlider,
  TestimonialsSlider,
  ActionBanner,
  FeaturesGrid,
  QuoteBanner,
  ContactSection,
  CollapsableInformation,
  ScrollToSectionMenu,
  CarouselSection,
} from '../components/slices'
import MasterPage from '../pages/Master'
import BannerWithForm from '../components/slices/BannerWithForm'

const Page = ({ data, location }) => {
  const [collapsableSectionActive, setCollapsableSectionActive] = React.useState(null)
  if (!data) { return null }
  const {
    prismicPage, prismicGeneralData, prismicNavBar, prismicFooter,
  } = data
  const {
    body, ogTitle, ogDescription, ogKeywords, favIco, ogImage, ogLogo, theme,
  } = prismicPage.data

  const ogData = {
    ogTitle, ogDescription, ogKeywords, favIco, ogImage, ogLogo,
  }

  return (
    <MasterPage
      navBarData={prismicNavBar}
      footerData={prismicFooter.data}
      ogData={ogData}
      location={location}
      generalData={prismicGeneralData.data}
      themeName={theme}
    >
      {body && body.map((bodyItem, index) => {
        switch (bodyItem.slice_type) {
          case 'hero':
            return <Hero data={bodyItem} />
          case 'awards_banner':
            return <AwardsBanner body={bodyItem} />
          case 'service_summary':
            return (
              <div className='full-padding'>
                <ServiceSummary body={bodyItem} />
              </div>
            )
          case 'projects_slider': {
            return (
              <div className={`max-width-container ${bodyItem.primary.image_size !== 'extra-small' ? 'overlap-previous-section' : ''}`} style={{ padding: '4rem 0' }}>
                <ProjectsSlider body={bodyItem} />
              </div>
            )
          }
          case 'testimonial_slider':
            return (
              <div className='testimonial-background'>
                <div className='max-width-container full-padding'>
                  <TestimonialsSlider body={bodyItem} />
                </div>
              </div>
            )
          case 'action_banner':
            return (
              <div className='max-width-container full-padding'>
                <ActionBanner body={bodyItem} />
              </div>
            )
          case 'features_grid':
            return (
              <div className='max-width-container full-padding'>
                <FeaturesGrid body={bodyItem} />
              </div>
            )
          case 'quote_banner':
            return (
              <div className='under-water-background'>
                <div className='max-width-container full-padding'>
                  <QuoteBanner body={bodyItem} />
                </div>
              </div>
            )
          case 'collapsable_information':
            return (
              <div className='max-width-container full-padding'>
                <CollapsableInformation body={bodyItem} openedId={collapsableSectionActive} />
              </div>
            )
          case 'contact_section':
            return (
              <div className={`max-width-container ${index === 0 ? 'first-section-padding' : 'full-padding'}`}>
                <ContactSection body={bodyItem} />
              </div>
            )
          case 'carousel_section':
            return (
              <div className='max-width-container full-padding'>
                <CarouselSection body={bodyItem} />
              </div>
            )
          case 'scroll_to_section_menu':
            return (
              <div className='max-width-container side-padding'>
                <ScrollToSectionMenu body={bodyItem} onClick={(id) => setCollapsableSectionActive(id)} />
              </div>
            )
          case 'banner_with_form':
            return (
              <div className='max-width-container side-padding'>
                <BannerWithForm body={bodyItem} />
              </div>
            )
          default: {
            return <div>{`${bodyItem.slice_type} Not implemented yet`}</div>
          }
        }
      })}
    </MasterPage>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicGeneralData {
      id
      data {
        telephone
        email
      }
    }
    prismicPage(lang: {eq: $lang}, id: {eq: $id}) {
       data {
        theme
        display_title {
          html
          text
          raw
        }
        ogTitle: og_title
        ogDescription: og_description
        ogKeywords: og_keywords
        favIco: fav_ico {
          url
        }
        ogLogo: og_logo {
          url
        }
        ogImage: og_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
         body {
            ...on PrismicPageBodyHero {
              ...HeroFragment
            }
            ...on PrismicPageBodyAwardsBanner {
              ...AwardsBannerFragment
            }
            ...on PrismicPageBodyServiceSummary {
              ...ServiceSummaryFragment
            }
            ...on PrismicPageBodyProjectsSlider {
              ...ProjectsSliderFragment
            }
            ...on PrismicPageBodyTestimonialSlider {
              ...TestimonialsSliderFragment
            }
            ...on PrismicPageBodyActionBanner {
              ...ActionBannerFragment
            }
            ...on PrismicPageBodyFeaturesGrid {
              ...FeaturesGridFragment
            }
            ...on PrismicPageBodyQuoteBanner {
              ...QuoteBannerFragment
            }
            ...on PrismicPageBodyContactSection {
              ...ContactSectionFragment
            }
            ...on PrismicPageBodyCollapsableInformation {
              ...CollapsableInformationFragment
            }
            ...on PrismicPageBodyScrollToSectionMenu {
              ...ScrollToSectionMenuFragment
            }
            ...on PrismicPageBodyBannerWithForm {
              ...BannerWithFormFragment
            }
            ...on PrismicPageBodyCarouselSection {
              ...CarouselSectionFragment
            }
          }
       }
    }
    prismicNavBar(lang: {eq: $lang}) {
      lang
      alternateLangs: alternate_languages {
        lang
      }
      data {
        logo {
          alt
          url
        }
        background_color
        items {
          item {
            ...NavItemFragment
          }
        }
      }
    }
    prismicFooter(lang: {eq: $lang}) {
      data {
        logo {
          alt
          url
        }
        address
        description {
          html
          text
          raw
        }
        links {
          linkItem: link_item {
            ...NavItemFragment
          }
        }
      }
    }
  }
`

export default Page
