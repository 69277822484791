import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { RichText } from '../html'
import Slider from '../slider'
import Title from './Title'
import PlainLink from '../link/PlainLink'
import QuoteIcon from '../../../static/images/quote-icon.svg'
import StarIcon from '../../../static/images/star-icon.svg'
import TrademeLogo from '../../../static/images/trademe-logo.svg'
import './TestimonialsSlider.scss'

const TestimonialsSlider = ({ body }) => {
  const { primary, items } = body
  return (
    <div className='testimonial-slider'>
      <div className='testimonials-header'>
        <Title title={primary.sliderTitle} centered />
        <RichText render={primary.subtitle.raw} />
      </div>
      <Slider fullLayout={false} controlsColor='primary' slidesToShow={{ mobile: 1, tablet: 2, desktop: 2 }}>
        {items.map(({ name, subName, testimonial }) => (
          <React.Fragment>
            <div className='slide-header'>
              <div className='person-details'>
                <h5>{name}</h5>
                <h6>{subName}</h6>
              </div>
              <QuoteIcon />
            </div>
            <div className='testimonial-description'>
              <RichText render={testimonial} />
              <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />
            </div>
          </React.Fragment>
        ))}
      </Slider>
      <div className='trademe-link-container'>
        <TrademeLogo />
        <PlainLink
          href='http://www.trademe.co.nz/services/domestic-services/cleaning/auction-771870266.htm?%20rel='
          target='_blank'
          rel='noopener noreferrer'
        >
          See More Testimonials
        </PlainLink>
      </div>
    </div>
  )
}


export const query = graphql`
  fragment TestimonialsSliderFragment on PrismicPageBodyTestimonialSlider {
    slice_type
    primary {
      sliderTitle: title {
        html
      }
      subtitle: subtitle {
        html
      }
    }
    items {
      name
      subName: sub_name
      testimonial {
        html
      }
    }
  }
`

TestimonialsSlider.propTypes = {
  body: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default TestimonialsSlider
