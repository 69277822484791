import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import Button from '../button'
import SendIcon from '../../../static/images/send.svg'
import './form.scss'
import { CONTACT_FORM } from '../../config/analytics-events'
import { encodeForm } from './utils'
import LoadingAnimation from '../loading'

const emptyFields = {
  inputName: '',
  inputEmail: '',
  inputPhone: '',
  inputMessage: '',
  inputProduct: '',
}

class ContactForm extends React.Component {
  constructor() {
    super()
    this.state = {
      ...emptyFields,
      contactMessage: null,
      sending: false,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  resetForm = () => {
    this.setState(emptyFields)
  }

  getProductOptions = () => {
    const { productOptions } = this.props
    return (
      productOptions.map((prod) => (
        <option value={prod}>
          {prod}
        </option>
      ))
    )
  }

  submitForm = (e) => {
    const _this = this
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    }
    const {
      inputName, inputEmail, inputMessage, inputPhone, inputProduct,
    } = this.state
    e.preventDefault()
    this.setState({ sending: true })

    fetch('/php/contact-form.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: encodeForm({
        emailTitle: 'Message From Pumas Cleaning Website',
        inputName,
        inputPhone,
        inputEmail,
        inputProduct,
        inputMessage,
      }),
    })
      .then((res) => {
        this.setState({ sending: false })
        if (res.ok) {
          ReactGA.event({
            category: CONTACT_FORM.category,
            action: CONTACT_FORM.successSubmitAction,
          })
          return res.json()
        }
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        return { error: 'Error sending form, please try again later.' }
      })
      .then((data) => {
        if (data.error || !data.success) {
          _this.setState({ contactMessage: { success: false, message: data.messageEnglish } })
        } else {
          _this.resetForm()
          _this.setState({ contactMessage: { success: true, message: data.messageEnglish } })
        }
      })
      .catch((error) => {
        console.error(error)
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        this.setState({ sending: false })
      })
  }

  render() {
    const {
      contactMessage,
      inputName,
      inputEmail,
      inputPhone,
      inputMessage,
      sending,
      inputProduct,
    } = this.state
    const {
      // prismic label texts
      labelName,
      labelEmail,
      labelPhone,
      labelProductOfInterest,
      labelMessage,
      sendLabel,
    } = this.props
    return (
      <div className='contact-form'>
        <form id='contact_form' name='contactform' data-toggle='validator' onSubmit={this.submitForm}>
          <div className='form-group'>
            <input
              value={inputName}
              onChange={this.handleChange}
              name='inputName'
              min='2'
              id='inputName'
              placeholder={labelName}
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputEmail}
              onChange={this.handleChange}
              name='inputEmail'
              id='inputEmail'
              placeholder={labelEmail}
              type='email'
              required
            />
          </div>

          {labelPhone && (
            <div className='form-group'>
              <input
                value={inputPhone}
                onChange={this.handleChange}
                name='inputPhone'
                id='inputPhone'
                placeholder={labelPhone}
                type='text'
              />
            </div>
          )}

          <div className='form-group'>
            <select
              value={inputProduct}
              onChange={this.handleChange}
              name='inputProduct'
              id='inputProduct'
            >
              <option value='' disabled selected>{labelProductOfInterest}</option>
              {this.getProductOptions()}
            </select>
          </div>

          <div className='form-group'>
            <textarea
              value={inputMessage}
              onChange={this.handleChange}
              id='inputMessage'
              name='inputMessage'
              placeholder={labelMessage}
              className='form-control'
              type='text'
              rows='6'
              required
            />
          </div>

          <div className='form-group'>
            <Button
              primary
              isButton
              type='submit'
              className='full-width-button'
              disabled={sending}
            >
              <SendIcon />
              {sending ? <div>{sendLabel} <LoadingAnimation /></div> : <div>{sendLabel}</div>}
            </Button>
          </div>
          {contactMessage && (
            <div className='contact-message-container'>
              <p
                className={contactMessage.success ? 'contact-message-success' : 'contact-message-error'}
              >
                {contactMessage.message}
              </p>
            </div>
          )}
        </form>
      </div>)
  }
}

ContactForm.propTypes = {
  productOptions: PropTypes.array.isRequired,
  labelName: PropTypes.string,
  labelEmail: PropTypes.string,
  labelPhone: PropTypes.string,
  labelProductOfInterest: PropTypes.string,
  labelMessage: PropTypes.string,
  sendLabel: PropTypes.string,
}

export default ContactForm
