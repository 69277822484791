import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import { RichText } from '../html'
import './ScrollToSectionMenu.scss'

const ScrollToSectionMenu = ({ body, onClick }) => {
  const { items } = body
  return (
    <div className='scroll-to-section-menu'>
      {items.map((item) => (
        <Link onClick={() => onClick(item.id)} to={`#${item.id}`}><RichText render={item.label} /></Link>
      ))}
    </div>
  )
}


export const query = graphql`
  fragment ScrollToSectionMenuFragment on PrismicPageBodyScrollToSectionMenu {
    slice_type
    primary {
      title {
        html
      }
    }
    items {
      label {
        html
      }
      id
    }
  }
`

ScrollToSectionMenu.propTypes = {
  body: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ScrollToSectionMenu
