import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Button from '../button'
import { RichText } from '../html'
import Title from './Title'
import DownArrow from '../../../static/images/down-arrow.svg'

import './CollapsableInformation.scss'

/*
* The images inside the collapsable section are tagged with labels in prismic.
* You can select inline-with-text or full-width depends how you want to show them in the layout.
* Read more here: https://user-guides.prismic.io/en/articles/1943308-add-custom-styles-to-rich-text
*/
const CollapsableInformation = ({ body, openedId }) => {
  const [activeSection, toggleActiveSection] = React
    .useState({ index: null, id: openedId })

  React.useEffect(() => {
    toggleActiveSection({ id: openedId })
  }, [openedId])

  const handleClick = (index = 0, id) => {
    if (activeSection && (index === activeSection.index || id === activeSection.id)) {
      toggleActiveSection(null)
    } else {
      toggleActiveSection({ index, id })
    }
  }

  const isActive = (index, id) => (
    activeSection && (activeSection.index === index || activeSection.id === id)
  )

  const { primary, items } = body

  return (
    <div className='collapsable-section-container'>
      <Title centered title={primary.collapsableSectionTitle} />
      <div className='single-row'>
        <RichText render={primary.description} />
        <Button to='/contact'>{primary.contactButtonText}</Button>
      </div>
      {items && items.map((item, index) => (
        <div
          id={item.id}
          className={`collapsable-item ${isActive(index, item.id) && 'collapsable-item-active'}`}
          key={item.id}
        >
          <button
            className={isActive(index, item.id) ? 'collapsable-active-button' : 'collapsable-button'}
            type='button'
            onClick={() => handleClick(index, item.id)}
          >
            <RichText render={item.title} />
            <DownArrow className={isActive(index, item.id) ? 'close-icon' : 'open-icon'} />
          </button>
          <div className={isActive(index, item.id) ? 'show-section' : 'hide-section'}>
            <RichText render={item.description} />
          </div>
        </div>
      ))}
    </div>
  )
}

export const query = graphql`
  fragment CollapsableInformationFragment on PrismicPageBodyCollapsableInformation {
    slice_type
    primary {
      collapsableSectionTitle: title {
        html
      }
      description {
        html
      }
      contactButtonText: contact_button_text
    }
    items {
      id
      title {
        html
        text
      }
      description {
        html
      }
    }
  }
`

CollapsableInformation.propTypes = {
  body: PropTypes.object,
  openedId: PropTypes.string,
}

export default CollapsableInformation
