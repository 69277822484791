import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { RichText } from '../html'
import './QuoteBanner.scss'

const QuoteBanner = ({ body }) => {
  const { primary } = body
  return (
    <div className='quote-banner'>
      <div>
        <RichText render={primary.quote} />
        <div>{primary.author}</div>
      </div>
    </div>
  )
}


export const query = graphql`
  fragment QuoteBannerFragment on PrismicPageBodyQuoteBanner {
    slice_type
    primary {
      quote {
        html
      }
      author
    }
  }
`

QuoteBanner.propTypes = {
  body: PropTypes.object.isRequired,
}

export default QuoteBanner
