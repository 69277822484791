import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import RichText from '../html/RichText'
import Media from '../media'
import HeroCircle from '../../../static/images/hero-circle.svg'

import './Hero.scss'
import ButtonBar from '../button/ButtonBar'
import PageContext from '../../context/PageContext'

const Hero = ({ data }) => {
  const { primary } = data
  const mediaDoc = primary.media.document
  const { themeName } = React.useContext(PageContext)
  const renderVideo = () => (
    mediaDoc && (
      <div className='hero-right-column'>
        <div className={`hero-image ${mediaDoc.data.hosted_video && 'hero-video'}`}>
          {mediaDoc && <Media id={mediaDoc.id} data={mediaDoc.data} />}
          {mediaDoc &&  <HeroCircle className='hero-circle show-desktop hide-mobile' />}
        </div>
      </div>
    )
  )
  return (
    <div className={`${themeName}-theme`} style={{ paddingBottom: '8rem' }}>
      <div className={`max-width-container hero-container  ${!mediaDoc && 'no-image-hero'}`}>
        <div className='hero-left-column'>
          {primary.subtitle && <RichText render={primary.subtitle} />}
          <RichText render={primary.title} />
          <div className='hero-description'><RichText render={primary.description} /></div>
          {primary.buttons && (
            <div className='hero-button'>
              {primary.buttons.document && <ButtonBar buttonArray={primary.buttons.document.data.button} />}
            </div>
          )}
        </div>
        {renderVideo()}
      </div>
    </div>
  )
}

Hero.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment HeroFragment on PrismicPageBodyHero {
    slice_type
    primary {
      title {
        html
      }
      subtitle {
        html
      }
      description {
        html
      }
      buttons {
        document {
          ...PrismicButtonFragment
        }
      }
      background
      media {
        ...PrismicMediaFragment
      }
    }
  }
`

export default Hero
