import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'

import Button from './index'

const ButtonBar = ({ buttonArray }) => {
  if (buttonArray) {
    return (
      <div className='button-bar'>{
        buttonArray.map((buttonData) => {
          const {
            url, title, label, icon, style,
          } = buttonData

          if (url) {
            return (
              <Button
                to={url}
                title={title}
                secondary={style === 'outlined'}
                icon={icon}
              >
                {label}
              </Button>
            )
          }

          // if no url specified we show just an image
          return <GatsbyImage className='image image-in-button-bar' fluid={icon.fluid} alt={icon.alt} />
        })}
      </div>)
  }
  return null
}

ButtonBar.propTypes = {
  buttonArray: PropTypes.array,
}

export default ButtonBar
