import React from 'react'
import PropTypes from 'prop-types'

import './link.scss'

const PlainLink = ({ children, ...rest }) => (
  <a {...rest} className='hype-link'>{children}</a>
)

PlainLink.propTypes = {
  children: PropTypes.string,
}
export default PlainLink
