import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Title from './Title'
import { RichText, isEmptyText } from '../html'
import './FeaturesGrid.scss'

const FeaturesGrid = ({ body }) => {
  const { items, primary } = body

  const isGridLayout = primary && primary.layout === 'grid'

  const renderItem = (item) => {
    const { icon, title, description } = item
    const image = icon && <img src={icon.url} alt={icon.alt} />
    const itemTitle = <RichText render={title} />
    const itemDescription = <RichText render={description} />

    if (isGridLayout) {
      return (
        <div key={itemTitle.text} className='features-item'>
          <div className='feature-header'>{image}{itemTitle}</div>
          <div className='feature-description'>{itemDescription}</div>
        </div>
      )
    }

    return (
      <div className='features-item'>
        {image}
        <div>
          {itemTitle}
          {itemDescription}
        </div>
      </div>
    )
  }

  return (
    <div>
      {!isEmptyText(primary.title) && (
        <div className='features-section-title'>
          <Title centered title={primary.title} />
        </div>
      )}
      <div className={`${isGridLayout ? 'features-grid' : 'features-full-width'}`}>
        {items && items.map(renderItem)}
      </div>
      {!isEmptyText(primary.description) && (
        <div className='features-grid-bottom-link'>
          <RichText render={primary.description} />
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  fragment FeaturesGridFragment on PrismicPageBodyFeaturesGrid {
    slice_type
    primary {
      title {
        html
        text
      }
      layout
      description {
        html
        text
      }
    }
    items {
      title {
        html
        text
      }
      icon{
        url
      }
      description {
        html
      }
    }
  }
`

FeaturesGrid.propTypes = {
  body: PropTypes.object.isRequired,
}

export default FeaturesGrid
