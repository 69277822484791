import React from 'react'
import ReactGA from 'react-ga'

import { CALLBACK_CONTACT_FORM } from '../../config/analytics-events'
import SendIcon from '../../../static/images/send.svg'

import Button from '../button'
import './form.scss'

const emptyFields = {
  inputName: '',
  inputEmail: '',
  inputPhone: '',
  contactMethod: '',
}

class RequestContactForm extends React.Component {
  constructor() {
    super()
    this.state = {
      ...emptyFields,
      contactMessage: null,
      sending: false,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  formEncode = (obj) => {
    const str = []
    for (const p in obj) { str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`) }
    return str.join('&')
  }

  resetForm = () => {
    this.setState(emptyFields)
  }

  submitForm = (e) => {
    const _this = this
    const { contactMethod } = this.state
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    } if (contactMethod === '') {
      e.preventDefault()
      this.setState({ contactMessage: { success: false, message: 'Select a preferred method of contact' } })
      return false
    }
    const {
      inputName, inputEmail, inputPhone,
    } = this.state
    e.preventDefault()
    this.setState({ sending: true })
    fetch('/php/contact-request.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: this.formEncode({
        emailTitle: 'Contact Request from Pumas Website',
        inputName,
        inputPhone,
        inputEmail,
      }),
    })
      .then((res) => {
        this.setState({ sending: false })
        if (res.ok) {
          ReactGA.event({
            category: CALLBACK_CONTACT_FORM.category,
            action: CALLBACK_CONTACT_FORM.successSubmitAction,
          })
          return res.json()
        }
        return { error: 'Error sending form, please try again later.' }
      })
      .then((data) => {
        if (data.error) {
          _this.setState({ contactMessage: { success: false, message: data.error } })
        } else {
          _this.resetForm()
          _this.setState({ contactMessage: { success: true, message: data.messageEnglish } })
        }
      })
      .catch((error) => {
        console.error(error)
        this.setState({ sending: false })
      })
  }

  changeContactMethod = (event) => {
    this.setState({
      contactMethod: event.target.value,
      contactMessage: null,
    })
  }

  render() {
    const {
      contactMessage, inputName, inputEmail, inputPhone, sending, contactMethod,
    } = this.state
    return (
      <div>
        <form
          className='contact-form request-contact-back-form'
          id='contact_request_form'
          name='contactform'
          data-toggle='validator'
          onSubmit={this.submitForm}
        >
          <input
            value={inputName}
            onChange={this.handleChange}
            name='inputName'
            min='2'
            id='inputName'
            placeholder='Enter your name'
            type='text'
            required
          />

          <div className='radio-buttons-group'>
            <label className='small-label'>How would you like us to contact you?</label>
            <div className='radio-buttons'>
              <input
                type='radio'
                id='phoneRadio'
                name='methodOfContact'
                value='phone'
                onChange={this.changeContactMethod}
              />
              <label
                className={contactMethod === 'phone' ? 'radio-label-selected' : 'radio-label'}
                htmlFor='phoneRadio'
              >
                Phone
              </label>

              <input
                type='radio'
                id='emailRadio'
                name='methodOfContact'
                value='email'
                onChange={this.changeContactMethod}
              />
              <label
                className={contactMethod === 'email' ? 'radio-label-selected' : 'radio-label'}
                htmlFor='emailRadio'
              >
                Email
              </label>
            </div>
          </div>


          {contactMethod === 'email' && <input
            value={inputEmail}
            onChange={this.handleChange}
            name='inputEmail'
            id='inputEmail'
            placeholder='Enter your email'
            type='email'
            required
          />}

          { contactMethod === 'phone' && <input
            value={inputPhone}
            onChange={this.handleChange}
            name='inputPhone'
            id='inputPhone'
            placeholder='Enter your phone'
            type='text'
            required
          />}

          <Button
            primary
            isButton
            type='submit'
            className='full-width-button'
            disabled={sending}
          >
            <SendIcon />
            <div>Send</div>
          </Button>
          <div className='contact-message-container'>
            {contactMessage
              && contactMessage.success
              && <p id='requestCallback' className='contact-message-success'>{contactMessage.message}</p>
            }
            {contactMessage
              && !contactMessage.success
              && <p className='contact-message-error'>{contactMessage.message}</p>
            }
          </div>
        </form>
      </div>
    )
  }
}

export default RequestContactForm
